import React from 'react';

const LoadingScreen = () => {
	return (
		<div className='container-lg main-content-container h-100 p-0'>
			<div className='d-none d-md-block h-100'>
				<div className='d-flex flex-column justify-content-center h-75'>
					<div className='d-flex flex-row justify-content-center align-items-center'>
						<div className='d-flex flex-row justify-content-center relative'>
							<div className='home-text-lg text-center'>
								<h1>Kattala Loppis</h1>
								<h1>Innehåll laddas...</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='d-block d-md-none h-100 home-content-container-sm'>
				<div className='d-flex flex-column justify-content-center align-items-center h-100'>
					<div className='d-flex flex-column'>
						<div className='px-0 text-center home-text-sm'>
							<h1>
								<h1>Kattala Loppis</h1>
								<h1>Innehåll laddas...</h1>
							</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default LoadingScreen;
