import React, { Suspense, lazy } from 'react';

import LoadingScreen from './components/LoadingScreen';
const LowScreen = lazy(() => import('./components/LowScreen'));
const Content = lazy(() => import('./components/main/MainFrame'));
const Navigation = lazy(() => import('./components/navigation/Navigation'));

function App() {
	return (
		<>
			<Suspense fallback={<LoadingScreen />}>
				<div className='App h-100 '>
					<div className='low-height-screen'>
						<LowScreen />
					</div>
					<main className='container-lg p-0 main-container'>
						<Content />
					</main>
					{/* <nav className='container-lg p-0 fixed-bottom nav-container'>
						<div className='row no-gutters h-100 m-0 p-0'>
							<Navigation />
						</div>
					</nav> */}
				</div>
			</Suspense>
		</>
	);
}

export default App;
