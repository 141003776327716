import { createReducer } from '@reduxjs/toolkit';
import { load_home, load_openhours, load_supply, load_find, load_contact } from '../actions';

const initialState = { loadedContent: 'home' };

const pageLoadedReducer = createReducer(initialState, (builder) => {
	builder
		.addCase(load_home, (state, action) => {
			state.loadedContent = 'index';
		})
		.addCase(load_openhours, (state, action) => {
			state.loadedContent = 'openHours';
		})
		.addCase(load_supply, (state, action) => {
			state.loadedContent = 'supply';
		})
		.addCase(load_find, (state, action) => {
			state.loadedContent = 'find';
		})
		.addCase(load_contact, (state, action) => {
			state.loadedContent = 'contact';
		});
});

export default pageLoadedReducer;
